import { Button, Flex, Text } from "@radix-ui/themes";
import StructBoi from "@/app/components/illustrations/StructBoi";

const ErrorMessage = ({}: { error?: Error; reset?: () => void }) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="5"
      style={{
        height: "100%",
      }}
    >
      <StructBoi />
      <Text size="6">Oh, hmmm... something went wrong.</Text>
      <Button onClick={() => window?.history?.back()}>Go back</Button>
    </Flex>
  );
};

export default ErrorMessage;
